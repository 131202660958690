.MuiDataGrid-viewport,
.MuiDataGrid-row,
.MuiDataGrid-renderingZone {
    max-height: fit-content !important;
}

.MuiDataGrid-cell {
    max-height: fit-content !important;
    overflow: auto;
    white-space: initial !important;
    line-height: 1 !important;
    display: flex !important;
    align-items: center;
    padding-top: 1% !important;
    padding-bottom: 1% !important;
}