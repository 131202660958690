:root {
    /* colores */
    --grisFont: #5f6273;
    --whiteBack: #dae2fc;
    --backField: #f0f2fa;
    --backBlue: #5e1847;
    --colorTittle: rgb(146, 21, 46);
    --hrColor: #f7f6fa;
    --textCardsColor: hsl(0, 65%, 27%);
    --colorBlue: hwb(351 11% 47%);
    --colorBlueClaro: rgb(185, 64, 84);
    --assetIconBack: #EAF6FF;
    --blancoPuro: #fff;
    --placeholderText: #828490;
    --backBlueClaro: hsl(112, 74%, 82%);
    --colorField: #9098b9;

    /* Tamaños en rem */
    --catorcePx: 0.875rem;
    --veintidosPx: 1.375rem;
    --cuarentaYochoPx: 3rem;
    --trecePx: 0.813rem;
    --ochoPx: 0.5rem;
    --treintaYdosPx: 2rem;
    --diezciseisPx: 1rem;
    --veintiCuatroPx: 1.5rem;
    --veintePx: 1.25rem;
    --diezYochoPx: 1.125rem;
    --treintaYseisPx: 2.25rem;
    --oncePx: 0.688rem;
    --quincePx: 0.938rem;
    --docePx: 0.75rem;
    --cientoCuatroPx: 6.5rem;
    --docientosPx: 12.5rem;
    --cuarentaPx: 2.5rem;
}

/* Generales */

* {
    font-family: 'Roboto';
}

*:focus {
    outline: none !important;
}

body {
    overflow: hidden;
}

#contenido {
    padding: 0.5% 1% 0;
    width: 100%;
    overflow: hidden;
}

#contenido .MuiBox-root header.MuiAppBar-root {
    position: relative !important;
    padding: 0 0 2%;
}

.mainlist {
    height: 100%;
}

.mainlist nav {
    height: 100%;
}

.btnSecundary.btnCancelar{
    padding: 5px 15% !important;
}

#ContenedorGneral{
    display: flex;
}

.ButtonGroup{
    align-items: center;
    display: flex; 
    width: auto;
}

#tittlePrincipal{
    display: flex;
}

#tittleResponsive{
    display: none;
}

.MuiPaper-root.MuiPaper-rounded {
    border-radius: 20px !important;
}

.uploadIcon {
    color: #8487A0;
    font-size: var(--diezYochoPx);
    margin: 0 0.5rem;
    cursor: pointer
}

#Alert {
    width: 100% !important;
    margin: 0.5rem 0;
}

p.uploadItem {
    font-size: var(--quincePx);
    font-weight: 400;
    color: var(--grisFont);
    margin: 0;
    padding: 12px;
}

label.uploadItem {
    margin: 0;
}

input.uploadItem {
    display: none;
}

.ContainerButtonGroup {
    justify-content: flex-start;
    width: 90%;
}

.ContainerButtonGroup .MuiGrid-root {
    margin: 0 !important;
}

.ContainerButtonGroup .MuiGrid-root .MuiGrid-item {
    margin: 0 !important;
}

.cantidadDocumentos {
    justify-content: flex-start !important;
}

.contenedorAssetGridMayor .MuiGrid-root .MuiGrid-item {
    margin: 0.2rem 0 0;
    line-height: 1;
    min-height: 1rem;
}

.btnPrimary.btnGm {
    display: flex;
}

.btnPrimary.btnA {
    display: none;
}

.MuiGrid-root .MuiGrid-item {
    margin: 0;
    line-height: 1;
}

.MuiButtonBase-root.aprobacion .MuiGrid-root {
    margin: 0.1rem 0 !important;
}

.MuiPopover-root .MuiPaper-root.MuiPaper-rounded {
    border-radius: 8px !important;
}

.tituloCards {
    font-weight: 700;
    font-size: 1.25rem;
    color: var(--grisFont);
    line-height: 1;
}

.displayNavLg {
    display: flex;
}

.displayNavXs {
    display: none !important;
}

a:hover{
    text-decoration: none;
}

/* Boton crear inventario */

.buttonCheckInventory {
    border-radius: 12px;
    text-align: center;
    margin: 15px 0;
    padding: 1px 5px;
    display: flex;
    align-items: center;
}

.MuiButton-root.buttonText {
    text-transform: none;
    font-size: 0.938rem;
    font-weight: 600;
}

.divCountLoad {
    background-color: #EF4682;
    color: #FCFCFC;
    font-size: 0.563rem;
    border-radius: 8px;
    margin-left: 2px;
    justify-content: center;
    align-items: center;
    display: flex;
}

/* textos, titulos */

.tittleGris {
    font-size: var(--veintidosPx) !important;
    color: var(--grisFont) !important;
    text-align: start !important;
    font-weight: 800 !important;
}

.labelStyle {
    color: var(--grisFont) !important;
    font-size: var(--catorcePx) !important;
    margin: 0 !important;
}

.labelFlex {
    color: var(--grisFont) !important;
    font-size: var(--catorcePx) !important;
    display: flex !important;
    margin-top: var(--ochoPx) !important;
}

.listItem {
    color: var(--colorTittle) !important;
}

.checklistDialogGeneral .MuiTypography-root.dialogText {
    font-size: 1rem;
    margin: 0;
    padding-left: 1rem;
    text-align: start;
}

.fontAa {
    color: var(--textCardsColor) !important;
    margin: 0 !important;
    font-size: var(--trecePx) !important;
}

.reportTittle {
    font-size: var(--veintidosPx);
    color: var(--grisFont);
    text-align: start;
    font-weight: 800;
    margin: var(--ochoPx) 0;
    margin-bottom: var(--veintiCuatroPx);
}

.MuiTypography-gutterBottom {
    margin: 0 !important;
}

.tittleBienes {
    font-size: var(--veintePx);
    font-weight: 600;
    color: var(--grisFont);
    text-align: start;
    margin: 2% 0;
}

.sincronizandoTittle {
    color: var(--grisFont);
    font-weight: 700
}

.seccional {
    color: #6e8ade;
    font-weight: 700;
    font-size: var(--diezYochoPx);
}

.tittleCard2 {
    display: none !important;
}

.tittleCard .MuiCardHeader-content span {
    font-size: var(--diezYochoPx) !important;
    font-weight: 800;
    text-align: 'start'
}

.tittleCardConsulta .MuiCardHeader-content span {
    padding: 2% 0% !important;
    font-size: var(--veintePx);
    color: var(--grisFont);
    font-weight: 800;
}

.tittleCardConsulta {
    padding-left: 5% !important;
    padding-right: 0% !important;
}

.MuiTypography-root {
    line-height: 1 !important;
}


.card-body {
    height: 100%;
    width: 16rem;
    background-color: var(--backBlue);
    text-align: center;
    border-top-right-radius: var(--cuarentaYochoPx);
    padding-right: 0;
}

.hrStyle {
    width: 100%;
    color: var(--hrColor);
    height: 1px;
    margin: var(--ochoPx) 0 !important;
}

.padding0 {
    padding-left: 2.5% !important;
    padding-bottom: 0.6% !important;
    padding-top: 0 !important;
}

.alertaConsultaBienes {
    width: auto !important;
    margin-top: var(--ochoPx);
    color: var(--colorBlue) !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.alertaConsultaBienes .MuiAlert-icon {
    color: var(--colorBlue) !important;
}

.iconInfo {
    color: var(--backBlueClaro);
    position: unset;
    font-size: var(--treintaYdosPx);
    width: auto;
    margin: 0 var(--ochoPx);
}

.input:-internal-autofill-selected {
    background: none !important;
}

.inputCC {
    margin-bottom: 7;
    background-color: var(--backField);
    border-radius: var(--diezciseisPx);
}

.inputCC .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 0 !important;
}

.card-2 {
    background-color: rgba(255, 255, 255, .3) !important;
    color: var(--blancoPuro) !important;
}

.card-2:hover {
    background-color: rgba(17, 117, 177, .8) !important;
}

.fiscaliaImagen2 {
    display: none;
}

.fiscaliaImagen {
    width: 70%;
    height: auto;
}

.iconsAsset {
    text-align: end;
    display: flex;
}

.PadDialogActions {
    padding: 0% 8% 4% 2% !important;
}

.InventarioCreado {
    width: 31.25rem;
    text-align: center;
}

.textArea {
    border-radius: var(--diezciseisPx);
    padding: 1.5%;
    outline: none;
    border: 0;
    background-color: var(--backField);
    color: var(--grisFont);
    resize: both !important;
    width: 90%;
}

.textArea#observationsAproved {
    width: 100%;
}

.orden1 {
    display: flex;
    margin: 2% 0 !important;
    width: 50%;
}

.orden2 {
    display: none !important;
}

.containerSobrante {
    padding-left: 1.6%;
}

.tittleOrden {
    color: var(--grisFont);
    font-weight: bold;
    font-size: var(--veintePx);
    margin: 0;
    margin-top: 0;
}


/* Formulario reportar sobrante */

.formReportSobrante .MuiCardContent-root.oDivisor {
    padding: 1.3% !important;
    padding-top: 1.6% !important;
}

.oDivisor p {
    font-size: var(--catorcePx);
    color: var(--grisFont);
    text-align: center;
    margin: 0;
}

.formReportSobrante .MuiCardContent-root {
    padding: 0 10% !important;
}

.btnEditar2 {
    display: none !important;
}

.MuiButtonBase-root.btnSecundary {
    color: var(--colorTittle) !important;
    border: 2px solid var(--whiteBack) !important;
    border-radius: 12px !important;
    text-transform: none !important;
    padding: 5px 2%;
    outline: none !important;
}

.MuiButtonBase-root.btnPrimary {
    border-radius: 12px !important;
    font-weight: 500 !important;
    background-color: var(--colorBlue) !important;
    border: 0 !important;
    color: #fff !important;
    text-transform: none !important;
    outline: none !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: var(--quincePx) !important;
    padding: 7px 16px;
}

.alertaRegistro .MuiAlert-message {
    line-height: 1;
}

/* Notificaciones */

.fechaNotificacion {
    text-align: end;
    padding-right: var(--diezciseisPx);
    font-size: var(--oncePx) !important;
    color: var(--textCardsColor);
    padding-top: 5px;
}

.tituloMensaje2 {
    display: none !important;
}

.contenedorMensaje {
    padding: 16px 0px;
}

.contentMensaje {
    font-size: var(--trecePx) !important;
    color: var(--textCardsColor) !important;
    padding: 0 10px 5px 0;
}

.mensajeResponsive {
    display: none;
}

.iconNotifycations {
    color: var(--colorBlueClaro);
}

/* box */
.box-2 {
    background-color: rgba(10, 82, 165, .3);
}

@media (min-width:768px) {
    .box {
        width: 100%;
    }
}

.box {
    background-color: var(--blancoPuro);
    width: 95%;
    margin: 30px auto;
    overflow: scroll;
}

.dashed-box {
    height: 250px;
    width: 90%;
    border: 5px dashed #bdbdbd;
    background-color: #f7fafc;
}

/* MenuItem */

.menuItemColor {
    color: var(--grisFont);
    font-size: var(--catorcePx);
    font-weight: 500;
}

.menuItemColor.MuiListItem-root.MuiListItem-button:hover {
    background-color: var(--whiteBack) !important;
}


/* image-sidebar */

.image-sidebar {
    padding: 20px;
    width: 60%;
    margin: 10px auto;
    border-radius: 50%;
    background-color: var(--blancoPuro);
}

.image-sidebar img {
    text-align: center;
    margin: 0 auto;
    margin-left: 15%;
}

.image-sidebar-2 {
    width: 150px;
    border-radius: 50%;
    background-color: var(--blancoPuro);
    text-align: center;
    height: 150px;
    align-items: center;
    margin: -30px 10px 10px 10px;
    z-index: 1000 !important;
}

.header-internal {
    background-color: rgba(10, 82, 165, .9);
}


/* Menu cambiar a tramitado */

.menuShadow .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    box-shadow: rgb(216 216 216 / 16%) 0px 3px 5px 1px !important;
}

.menuShadow .MuiPaper-root .MuiList-padding {
    padding: 0;
}

.MuiList-root .MuiButtonBase-root {
    color: var(--textCardsColor);
    font-size: var(--catorcePx);
    font-weight: 500;
}

.MuiMenuItem-root {
    color: var(--grisFont)
}

.MuiTooltip-popper>div {
    background: #ef4682 !important;
    border-radius: 12px !important;
}


/* Generales  Autocomplete */

.complete .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0 !important;
}

.complete input::placeholder {
    color: var(--placeholderText);
    font-weight: 500;
    font-size: var(--quincePx);
}

.MuiAutocomplete-listbox {
    width: 100% !important;
}

.MuiAutocomplete-listbox .MuiAutocomplete-option {
    width: 100% !important;
    color: var(--grisFont) !important;
    line-height: 1;
    margin-top: 1px !important;
}

.MuiAutocomplete-popper div li {
    font-size: var(--diezciseisPx) !important;
}

.MuiAutocomplete-popper div li:hover {
    background-color: var(--whiteBack) !important;
}

.MuiAutocomplete-popper div ul::-webkit-scrollbar {
    width: 6px;
}

.MuiAutocomplete-popper div ul::-webkit-scrollbar-track {
    color: var(--colorTittle);
}

.MuiAutocomplete-popper div ul::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px var(--colorTittle);
}


/* MuiDataGrid */

.MuiDataGrid-root .MuiDataGrid-footerContainer .MuiDataGrid-selectedRowCount {
    width: 100% !important;
}

.MuiDataGrid-root {
    color: var(--grisFont) !important;
    font-size: var(--trecePx) !important;
    font-weight: 500 !important;
}

.MuiDataGrid-cell {
    color: var(--textCardsColor) !important;
    font-size: var(--trecePx) !important;
    font-weight: 500 !important;
}

.MuiDataGrid-columnHeaderTitle {
    color: var(--grisFont) !important;
    font-size: var(--trecePx) !important;
    font-weight: 800 !important;
}

.MuiDataGrid-window::-webkit-scrollbar {
    width: 6px;
}

.MuiDataGrid-window::-webkit-scrollbar-track {
    color: var(--colorTittle);
}

.MuiDataGrid-window::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px var(--colorTittle);
}

.MuiDataGrid-window::-webkit-scrollbar {
    width: 6px;
}

.MuiDataGrid-window::-webkit-scrollbar-track {
    color: var(--colorTittle);
}

.MuiDataGrid-window::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px var(--colorTittle);
}

.MuiDataGrid-colCellTitle {
    font-weight: 800;
    font-size: var(--trecePx);
    color: var(--grisFont);
}

.MuiDataGrid-root {
    border-radius: var(--diezYochoPx) !important;
}


/* MuiMenu */

.MuiMenu-paper::-webkit-scrollbar {
    width: 6px;
}

.MuiMenu-paper::-webkit-scrollbar-track {
    color: var(--colorTittle);
}

.MuiMenu-paper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px var(--colorTittle);
}

.MuiTablePagination-root {
    color: var(--textCardsColor) !important;
}


/* MuiDialog */

.MuiDialogContent-root {
    padding: 18px 20px 5px !important;
}

.MuiDialogContent-root::-webkit-scrollbar {
    width: 6px;
}

.MuiDialogContent-root::-webkit-scrollbar-track {
    color: var(--colorTittle);
}

.MuiDialogContent-root::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px var(--colorTittle);
}

.MuiDialog-container .MuiPaper-root {
    padding: 5px;
}

.dialogReounded .MuiDialog-container.MuiDialog-scrollPaper .MuiPaper-rounded {
    border-radius: 20px !important;
    box-shadow: none !important;
    width: 50%;
    height: auto;
}

.MuiBackdrop-root {
    background-color: #879ee3ad !important;
}

.MuiPaper-elevation24 {
    box-shadow: none !important;
}

.MuiPaper-elevation8 {
    margin-top: 0.4rem;
    box-shadow: none !important;
}


/* Mui */

.MuiSwitch-track {
    background-color: #d6dbef !important;
}

.MuiBadge-badge {
    height: 16px !important;
    min-width: none !important;
}

.MuiBadge-badge.MuiBadge-anchorOriginBottomRightRectangular {
    width: fit-content !important;
    min-width: auto !important;
    height: fit-content !important;
}

.MuiBadge-anchorOriginBottomRightRectangle {
    bottom: 7px !important;
}

.MuiFormControlLabel-root {
    color: var(--grisFont);
    margin: 0;
}

.MuiOutlinedInput-inputMarginDense {
    margin-left: 0 !important;
}

.MuiInputBase-input {
    color: var(--grisFont) !important;
    font-size: var(--catorcePx) !important;
}

.MuiOutlinedInput-input {
    padding-left: 0.2rem;
}

.MuiOutlinedInput-input {
    padding: 10px !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0) !important;
}


/* Surplus */

.cardStateSurplus {
    color: var(--blancoPuro) !important;
    text-transform: none !important;
    font-size: var(--oncePx) !important;
    padding: 1px 8px !important;
}

.MuiButton-text.btnSecundary {
    color: var(--colorTittle) !important;
    border: 2px solid var(--whiteBack) !important;
    border-radius: 12px !important;
    text-transform: none !important;
    padding: 5px 2%;
    outline: none !important;
}

.MuiButton-text.btnPrimary {
    border-radius: 12px !important;
    font-weight: 500 !important;
    background-color: var(--colorBlue) !important;
    border: 0 !important;
    color: #fff !important;
    text-transform: none !important;
    outline: none !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: var(--quincePx) !important;
    padding: 7px 16px;
}

.btnEnviar {
    background-color: var(--backBlueClaro) !important;
    font-size: var(--quincePx) !important;
    color: var(--colorBlue) !important;
    font-weight: 600 !important;
    border-radius: 12px !important;
}

.btnEnviar2 {
    display: none !important;
}

.btnGuardar {
    background-color: var(--backBlueClaro) !important;
    font-size: var(--quincePx) !important;
    text-transform: none !important;
    color: #fff !important;
    font-weight: 500 !important;
    border-radius: 12px !important;
}

.IndicadorImportancia {
    color: red;
    padding-left: 0.2rem;
}


/* Barra lateral usuario */

.NavElement.active .MuiListItemIcon-root {
    background-color: var(--backBlueClaro);
    color: var(--colorBlueClaro) !important;
    border-radius: 16px;
    min-width: 19%;
    min-height: 55%;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
}

.NavElement.active .MuiListItem-root .MuiListItemText-root {
    border-start-start-radius: 70px !important;
    border-end-start-radius: 70px !important;
    background-color: var(--colorBlueClaro) !important;
    text-decoration: none !important;
    color: var(--blancoPuro) !important;
    padding: var(--diezciseisPx) 0;
    padding-left: 52px;
    z-index: 1;
}

.NavElement.active .MuiListItemIcon-root {
    background-color: var(--backBlueClaro);
    color: var(--colorBlueClaro) !important;
    border-radius: 16px;
    min-width: 19%;
    min-height: 55%;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
}

.modalAcitve {
    border-start-start-radius: 70px !important;
    border-end-start-radius: 70px !important;
    background-color: var(--colorBlueClaro) !important;
    text-decoration: none !important;
    color: var(--blancoPuro) !important;
    padding: var(--diezciseisPx) 0;
    padding-left: 52px;
    z-index: 1;
}

.modalActiveIcon {
    color: var(--colorTittle) !important;
    background-color: var(--backBlueClaro);
    border-radius: 16px;
    min-width: 19% !important;
    height: 55%;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    right: 86%;
}

.iconButtonNav {
    display: none !important;
}


/* Dialog */

.dialogDocuments .MuiDialog-container .MuiPaper-root {
    width: 32% !important;
}

.dialogDocuments .MuiDialogContent-root {
    padding: 1rem 2rem !important;
}

.dialogDocuments .MuiPaper-root .MuiDialogTitle-root {
    font-weight: bold;
    color: var(--grisFont);
    padding: 0.2rem 1rem 1rem 1rem;
}

.dialogDocuments .MuiPaper-root p.MuiTypography-root {
    padding: 0 2rem;
}

.dialogDocuments .MuiPaper-root .MuiDialogContent-root {
    width: auto;
}

.DialogInventWithout .MuiDialog-container .MuiPaper-root {
    padding: 1.5rem 0rem;
    width: 100%;
}

.DialogInventWithout .MuiPaper-root .MuiDialogTitle-root {
    font-weight: bold;
    color: var(--grisFont);
    padding: 0.2rem 1rem 1rem 1rem;
}

.DialogInventWithout .MuiPaper-root p.MuiTypography-root {
    padding: 0 2rem;
}

.DialogInventWithout .MuiPaper-root .MuiDialogContent-root {
    width: 12rem;
}

.dialogNotifications .MuiDialog-container .MuiPaper-root {
    padding: var(--ochoPx);
}

.dialogTttleReimpresión {
    font-size: var(--veintePx);
    font-weight: 800;
    color: var(--grisFont);
}

.containerCorreoBienes1 .MuiDialog-container .MuiPaper-root {
    border-radius: var(--diezciseisPx);
}

.containerCorreoBienes1 .MuiDialogContent-root {
    padding: 10px 30px;
}

.containerCorreoBienes1 .MuiDialog-paper {
    width: 30%;
}

.containerCorreoBienes1 .MuiDialog-container .MuiPaper-root .MuiInputBase-input {
    color: var(--grisFont) !important;
    font-size: var(--catorcePx) !important;
    border-radius: var(--treintaYdosPx);
}

.containerCorreoBienes2 .MuiDialog-container .MuiPaper-root {
    border-radius: var(--diezciseisPx);
}

.containerCorreoBienes2 .MuiDialogContent-root {
    padding: 10px 30px;
}

.containerCorreoBienes2 .MuiDialog-paper {
    width: 50%;
}

.containerCorreoBienes2 .MuiDialog-container .MuiPaper-root .MuiInputBase-input {
    color: var(--grisFont) !important;
    font-size: var(--catorcePx) !important;
    border-radius: var(--treintaYdosPx);
}

.containerCardCorreoBien .MuiDialog-container .MuiPaper-root {
    border-radius: var(--diezciseisPx);
}

.containerCardCorreoBien .MuiDialogContent-root {
    padding: 10px 30px;
}

.containerCardCorreoBien .MuiDialog-paper {
    width: 65%;
    max-width: fit-content;
}

.containerCardCorreoBien .MuiDialog-container .MuiPaper-root .MuiInputBase-input {
    color: var(--grisFont) !important;
    font-size: var(--catorcePx) !important;
    border-radius: var(--treintaYdosPx);
}

.dialogTttle {
    padding: 16px 15px 0 !important;
    font-weight: 700 !important;
    font-size: var(--veintePx) !important;
    color: var(--grisFont) !important;
}

.DialogOrdenar .MuiPaper-root {
    width: 40%;
    height: auto;
}

#DialogOrdenar {
    padding: 0 !important;
}

.textCards {
    color: var(--textCardsColor) !important;
    font-size: var(--trecePx) !important;
    line-height: 1 !important;
}

.ubicacionInput {
    background: var(--backField);
    border-radius: var(--treintaYdosPx);
    width: auto;
    height: auto;
    justify-content: center;
}

.labelModal {
    line-height: 1;
    margin: 0.1rem;
    margin-bottom: var(--ochoPx);
    color: var(--grisFont);
    font-size: var(--catorcePx);
}

.select-filtros {
    border-radius: 50px !important;
    background-color: var(--backField);
    height: var(--treintaYseisPx);
}

.select-filtros .MuiPopover-root>.MuiPaper-rounded {
    border-radius: 50px !important;
}

.MuiPopover-root .MuiPaper-rounded {
    border-radius: 18px !important;
}

.CheckListDialog .MuiDialog-container .MuiPaper-root {
    width: 60 !important;
    height: 33.563rem;
    border-radius: var(--diezciseisPx);
}

.CheckListDialog .MuiDialog-container .MuiDialog-paperWidthSm {
    max-width: none !important;
    width: 60 !important;
    height: 33.563rem !important;
    border-radius: var(--diezciseisPx) !important;
    box-shadow: none !important;
}

.MuiDialog-paperWidthSm {
    max-width: 60 !important;
    border-radius: var(--treintaYdosPx) !important;
}

.estadoBien.dialogReounded .MuiDialog-container.MuiDialog-scrollPaper .MuiPaper-rounded {
    width: 32%;
}

.dialogFiltros .MuiDialog-container .MuiPaper-root {
    width: 45%;
    border-radius: 20px !important;
    box-shadow: none !important;
}

.MuiDialogContent-root .dialogText {
    color: var(--grisFont);
    font-weight: 400;
    text-align: justify;
    font-family: 'roboto';
    font-size: var(--catorcePx);
}

.labelUbication {
    padding-left: var(--ochoPx);
    line-height: 1;
    margin: 0;
    margin-top: var(--ochoPx);
    color: var(--grisFont);
    font-size: var(--catorcePx);
}

#Filtros {
    padding: var(--veintiCuatroPx) !important;
    padding-top: var(--diezciseisPx) !important;
}

#DialogEmail {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

#DialogOrdenar .MuiGrid-root .MuiGrid-root .MuiFormGroup-root .orden1 .MuiFormControlLabel-root {
    margin: auto 5% !important;
}

#DialogOrdenar .MuiGrid-root .MuiGrid-root .MuiFormGroup-root.estado .MuiFormControlLabel-root {
    margin: 2% 0 !important;
    width: 50%;
}

#DialogOrdenar .MuiGrid-root .MuiGrid-root .MuiFormGroup-root.novedad .MuiFormControlLabel-root {
    margin: 2% 0 !important;
    width: 50%;
}

#DialogOrdenar .MuiGrid-root .MuiGrid-root .MuiFormGroup-root {
    justify-content: end;
}

.dialogChangeLost .MuiDialog-container .MuiPaper-root {
    width: 50%;
    margin: auto;
}

.dialogReounded.dialogUbication .MuiDialog-container.MuiDialog-scrollPaper .MuiPaper-rounded {
    width: 70%;
}

.RoportInventory .MuiDialog-container .MuiPaper-root {
    width: 35% !important;
}

.RoportInventory .MuiDialog-container .MuiPaper-root .MuiDialogActions-root {
    margin: 1% 5%;
}

.RoportInventory .MuiDialog-container .MuiPaper-root .MuiDialogActions-root .btnPrimary {
    padding: 6px 5% !important;
}

#DialogOrdenar {
    padding: 0 2.95rem var(--diezciseisPx) 0.95rem !important;
}

#DialogOrdenar .MuiGrid-spacing-xs-2>.MuiGrid-item {
    padding: 0 !important;
}


/* Contenedor mensaje email */

.tox-tinymce {
    border-radius: 20px !important;
}

.tox .tox-edit-area__iframe {
    background-color: var(--backField) !important;
}

.tox-tinymce {
    border-color: 0px !important;
}

.tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
    border: 0 !important;
}


/* Cards */

.containerReport .MuiPaper-elevation1 {
    box-shadow: 3px 3px 20px rgb(214, 224, 244, 30%);
    border-radius: var(--diezciseisPx);
}

.tittleCard .MuiCardHeader-content span {
    font-size: var(--veintePx);
    color: var(--grisFont);
    font-weight: 800;
}

.MuiTypography-root.descripcionBien {
    color: var(--grisFont) !important;
    font-family: 'roboto' !important;
    font-weight: 800 !important;
    font-size: var(--catorcePx) !important;
    line-height: 1 !important;
    align-self: center;
}

.bAsset {
    font-weight: bold;
    font-size: var(--trecePx);
    color: var(--grisFont);
    word-wrap: break-word;
    width: 90%;
    white-space: pre-line;
}

/* Paper Asset */

.dependenciaXs {
    display: none;
}

.dependenciaLg {
    display: flex;
}

.dependenciaLg .MuiGrid-root {
    padding: 0 !important;
}

.assetDialogActions1 {
    padding: 2% 3% 3% 0% !important;
}

.assetDialogActions2 {
    padding: var(--ochoPx) var(--veintiCuatroPx) !important;
}

.MuiIconButton-label .MuiSvgIcon-root {
    transition: none;
    height: 1em !important;
}

.asset_icon {
    background-color: var(--assetIconBack) !important;
    width: 76px;
    height: 76px;
    border-radius: 50% !important;
    align-self: center;
    margin-bottom: 0.5rem !important;
}

.asset_icon .MuiIconButton-label img {
    width: 75%;
    height: 28px;
}

.contenedorIconButton {
    display: none;
}

.contenedorAsset {
    margin: 1% !important;
}


/* Historial Inventarios */

.ContenedorHistorial {
    color: var(--grisFont);
    background-color: var(--backField);
    border-radius: var(--diezciseisPx);
    border: 0;
    align-self: center;
    text-align: center;
    width: 17.813rem !important;
    height: var(--treintaYseisPx)
}

.ContenedorHistorial .MuiGrid-item {
    margin-top: 0 !important;
}

.verificacionLink span {
    color: var(--colorBlue);
    font-size: var(--diezciseisPx);
    font-weight: 800;
    text-decoration: underline;
}

.ordenarHistorial {
    margin-left: var(--diezciseisPx);
}

.ordenarHistorial .MuiFormControlLabel-root .MuiIconButton-root .MuiIconButton-label:checked {
    color: var(--backBlueClaro) !important;
}


/* Login pantalla completa*/

.containerLogin {
    width: 100vw;
    height: 100vh;
    text-align: center;
    justify-content: center;
    background-size: cover;
    background-position: bottom;
    background-image: url('./assets/img/imagen_congreso.jpg');
}

.containerLogin .rootLogin {
    width: 43%;
    float: right;
    background-color: var(--backBlue);
    color: var(--blancoPuro);
    border-radius: 0 0 0 var(--docientosPx) !important;
    padding: 7% 10%;
}

.MuiCardContent-root.cardTtuloLogin {
    width: 80%;
    margin: auto;
    max-width: none;
    padding: 0;
    padding-bottom: 5%;
}

.MuiTypography-root.tituloLogin {
    width: 100%;
    margin: auto;
    font-size: var(--treintaYseisPx) !important;
    font-weight: 800;
    line-height: 1;
    font-family: 'Roboto';
}

.btnLogin {
    background-color: var(--colorTittle) !important;
    width: 65% !important;
    font-size: var(--diezciseisPx) !important;
    color: var(--blancoPuro) !important;
    border-radius: var(--treintaYdosPx) !important;
    text-transform: none !important;
}

.field {
    background-color: var(--backField) !important;
    border-radius: 50px;
    color: var(--colorField) !important;
    width: 65%;
    padding: 0.5rem 1rem;
    border: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: var(--catorcePx) !important;
}

.ImgLogoLogin {
    width: 90%;
    padding-top: 10%;
}

.ImgTitulo {
    width: 65%;
    padding-top: 5%;
}

/* Barra lateral */

.fiscaliaImagen2 {
    display: none;
}

.fiscaliaImagen {
    width: 70%;
    height: auto;
}

.NavElement.active .MuiListItemIcon-root {
    background-color: var(--backBlueClaro);
    color: var(--colorBlueClaro) !important;
    border-radius: 16px;
    min-width: 19%;
    min-height: 55%;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    right: 86%;
}

.redirectIcon {
    background-color: var(--backBlueClaro);
    color: var(--colorBlueClaro) !important;
    border-radius: 12px;
    z-index: 2;
    position: absolute;
    padding: 3%;
    width: fit-content;
    left: 0;
    min-width: 1% !important;
}

.redirectAcitve {
    border-start-start-radius: 70px !important;
    border-end-start-radius: 70px !important;
    background-color: var(--colorBlueClaro) !important;
    text-decoration: none !important;
    color: var(--blancoPuro) !important;
    padding: var(--diezciseisPx) 0 !important;
    padding-left: 20% !important;
    z-index: 1;
    margin-left: 4% !important;
}

.contenedorBuscador {
    color: var(--placeholderText);
    width: 100%;
    margin: 0 !important;
}

.iconButtonNav {
    display: none !important;
}

.contenedorAssetGrid {
    flex-direction: row;
    padding-right: 1%;
}

.contenedorMedio .Fila1 {
    flex-direction: row;
}

.formReportSobrante {
    width: 50%;
    padding: 0 var(--diezciseisPx);
}

#Ubicacion {
    background: var(--backField);
    border-radius: var(--treintaYdosPx);
    border: 0;
    color: var(--grisFont);
    width: 100%;
    height: auto;
    padding: 9px;
}

.iconsAssetTop {
    display: none;
}

.iconsAssetBottom {
    display: unset;
}

.iconsAssetBottom .MuiGrid-root.MuiGrid-item {
    margin: 0 0.4rem !important;
}

.contenedorMedio .Fila1 {
    flex-direction: row;
}

.MuiTablePagination-root:last-child {
    width: 100%;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0 !important;
}

.cardRegistroSobrante.MuiPaper-root.MuiCard-root.cardRegistroSobrante.MuiPaper-elevation1.MuiPaper-rounded {
    border-radius: var(--diezciseisPx) !important;
    box-shadow: 3px 3px 20px rgb(214, 224, 244, 30%);
}

.cardRegistroSobrante .MuiGrid-root.MuiGrid-container {
    padding: 0 var(--treintaYdosPx);
}

.containerSobrante .MuiPaper-root .MuiAlert-message .MuiGrid-root .MuiGrid-root .MuiTypography-root {
    font-size: var(--catorcePx);
    font-weight: 400;
}

.ReportSobranteFooter .btnPrimary {
    padding: 6% 15% !important;
}

.ReportSobranteFooter .btnSecundary {
    padding: 6% 15% !important;
}

.FooterForm .btnPrimary {
    padding: 7px 4% !important;
}

.btnEnviar1 {
    margin-top: 1% !important;
    padding: 6px 16px !important;
}

.papperSurplus {
    padding: var(--diezciseisPx) 0;
    border-radius: var(--treintaYdosPx) !important;
    margin: 1% 0 2% 0;
    border: 0;
    max-width: none;
    box-shadow: 3px 3px 20px 0px rgb(214, 224, 244, 30%) !important;
    position: relative;
}

.btnEditar2.MuiButton-root {
    min-width: fit-content;
}

.btnEditar1.MuiButtonBase-root {
    border: 1px solid var(--colorBlue);
    font-size: var(--oncePx);
    font-weight: 600;
    margin-left: 0.2rem;
    padding: 0;
    width: 3.875rem;
    height: var(--veintidosPx);
    text-transform: none;
}

.contenedorNone {
    margin-top: 5px;
}

.actionsMailDialog {
    justify-content: end !important;
    padding: 16px 10% !important;
}

.actionsFinded {
    justify-content: end !important;
    padding: 1% 5% !important;
}

.actionsSended {
    padding: 12px 5% !important;
}


/* Notificaciones */

.mensajePrincipal .contentMensaje {
    margin-top: 0.3rem;
}

.MuiTypography-root.tittleNotification {
    font-weight: 800 !important;
    font-size: 1rem;
}

/* Pantallas Grandes - De 1400px a 1200px*/

@media (max-width: 1399.98px) {


    /* Generales */

    .contenedorNone {
        padding-right: 1%;
    }

    .orden2 {
        display: none;
    }

    .orden1.MuiFormControlLabel-labelPlacementTop {
        margin: 0;
        width: 49%;
    }

    .tittleCard2 {
        display: none;
    }

    .btnPrimary {
        padding: 7px 3% !important;
    }

    .btnSecundary {
        padding: 6px 2% !important;
    }


    /* Login */

    .containerLogin {
        width: 100vw;
        height: 100vh;
        text-align: center;
        justify-content: center;
        background-size: cover;
        background-position: bottom;
        background-image: url('./assets/img/imagen_congreso.jpg');
    }

    .containerLogin .rootLogin {
        width: 42%;
        float: right;
        background-color: var(--backBlue);
        color: var(--blancoPuro);
        border-radius: 0 0 0 var(--docientosPx) !important;
        padding: 5% 10%;
    }

    .MuiCardContent-root.cardTtuloLogin {
        width: 80%;
        margin: auto;
        max-width: none;
        padding: 0;
        padding-bottom: 5%;
    }

    .MuiTypography-root.tituloLogin {
        width: 100%;
        margin: auto;
        font-size: var(--treintaYseisPx) !important;
        font-weight: 800;
        line-height: 1;
        font-family: 'Roboto';
    }

    .btnLogin {
        background-color: var(--colorTittle) !important;
        width: 65% !important;
        font-size: var(--diezciseisPx) !important;
        color: var(--blancoPuro) !important;
        border-radius: var(--treintaYdosPx) !important;
        text-transform: none !important;
    }

    .field {
        background-color: var(--backField) !important;
        border-radius: 50px;
        color: var(--colorField) !important;
        padding-left: var(--ochoPx) !important;
        width: 65%;
        padding: var(--ochoPx) 0;
        border: 0;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: var(--catorcePx) !important;
    }

    .ImgLogoLogin {
        width: 50%;
        padding-top: 15%;
    }


    /* Barra lateral */

    .fiscaliaImagen2 {
        display: none;
    }

    .fiscaliaImagen {
        width: 70%;
        height: auto;
    }

    .iconButtonNav {
        display: none !important;
    }


    /* Assets */

    .MuiTypography-caption.descripcionBien {
        font-size: var(--catorcePx) !important;
    }

    .asset_icon {
        padding: 12px 9% !important;
    }

    .asset_icon .MuiIconButton-label img {
        width: 100%;
        height: 28px;
    }

    .contenedorBuscador {
        color: var(--placeholderText);
        margin: 0;
    }

    .contenedorMedio .Fila1 {
        flex-direction: row;
    }

    .iconsAssetTop {
        display: none;
    }


    /* Dialogs */

    .DialogOrdenar .MuiPaper-root {
        width: 56%;
    }

    #DialogOrdenar {
        padding: 0 2.95rem var(--diezciseisPx) 0.95rem !important;
    }

    #DialogOrdenar .MuiGrid-spacing-xs-2>.MuiGrid-item {
        padding: 0 !important;
    }

    #Ubicacion {
        background: var(--backField);
        border-radius: var(--treintaYdosPx);
        border: 0;
        color: var(--grisFont);
        width: 100%;
        padding-left: var(--diezciseisPx);
    }

    .dialogChangeLost .MuiDialog-container .MuiPaper-root {
        width: 50%;
    }

    .actionsSended {
        padding: 10px 6% !important;
    }


    /* Container correo */

    .containerCorreoBienes1 .MuiDialog-paper {
        width: 35%;
    }

    .containerCorreoBienes1 .MuiDialog-container .MuiDialog-paper .MuiDialogActions-root {
        padding: 20px 7% !important;
    }

    .containerCorreoBienes1 .MuiDialog-container .MuiDialog-paper .MuiDialogActions-root .btnPrimary {
        padding: 7px 5% !important;
    }

    .containerCorreoBienes1 .MuiDialog-container .MuiDialog-paper .MuiDialogActions-root .btnSecundary {
        padding: 7px 5% !important;
    }


    /* formulario reportar sobrante */

    .containerReport .MuiGrid-root .formReportSobrante {
        width: 50%;
    }

    .MuiOutlinedInput-input {
        padding: 11px 15% 11px 3% !important;
    }

    .cardRegistroSobrante .MuiGrid-root.MuiGrid-container {
        padding: 0 3% !important;
    }

    .btnEnviar1 {
        margin-top: 1% !important;
    }

    .ReportSobranteFooter .btnPrimary {
        padding: 6% 15% !important;
    }

    .ReportSobranteFooter .btnSecundary {
        padding: 6% 15% !important;
    }

    .RoportInventory .MuiDialog-container .MuiPaper-root {
        width: 45% !important;
    }

    .RoportInventory .MuiDialog-container .MuiPaper-root .MuiDialogActions-root {
        margin: 1% 5%;
    }
}

@media (max-width: 1199.98px) {


    /* Generales */

    .makeStyles-toolbar-4 {
        width: 94%;
    }

    .orden2 {
        display: none;
    }

    .orden1.MuiFormControlLabel-labelPlacementTop {
        margin: 0 !important;
        width: 50%;
    }

    .tittleCard2 {
        display: none;
    }


    /* Login */

    .containerLogin {
        width: 100vw;
        height: 100vh;
        text-align: center;
        justify-content: center;
        background-size: cover;
        background-position: bottom;
        background-image: url('./assets/img/imagen_congreso.jpg');
    }

    .containerLogin .rootLogin {
        width: 50%;
        float: right;
        background-color: var(--backBlue);
        color: var(--blancoPuro);
        border-end-start-radius: var(--docientosPx);
        padding: 5% 10%;
    }

    .MuiCardContent-root.cardTtuloLogin {
        width: 80%;
        margin: auto;
        max-width: none;
        padding: 0;
        padding-bottom: 5%;
    }

    .MuiTypography-root.tituloLogin {
        width: 100%;
        margin: auto;
        font-size: var(--treintaYseisPx) !important;
        font-weight: 800;
        line-height: 1;
        font-family: 'Roboto';
    }

    .btnLogin {
        background-color: var(--colorTittle) !important;
        width: 65% !important;
        font-size: var(--diezciseisPx) !important;
        color: var(--blancoPuro) !important;
        border-radius: var(--treintaYdosPx) !important;
        text-transform: none !important;
    }

    .field {
        background-color: var(--backField) !important;
        border-radius: 50px;
        color: var(--colorField) !important;
        padding-left: var(--ochoPx) !important;
        width: 65%;
        padding: var(--ochoPx) 0;
        border: 0;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: var(--catorcePx) !important;
    }

    .ImgLogoLogin {
        width: 50%;
        padding-top: 15%;
    }


    /* Barra lateral */

    .fiscaliaImagen2 {
        display: none;
    }

    .fiscaliaImagen {
        width: 70%;
        height: auto;
    }

    .NavElement.active .MuiListItemIcon-root {
        background-color: var(--backBlueClaro);
        color: var(--colorBlueClaro) !important;
        border-radius: 10px;
        min-width: 19%;
        min-height: 55%;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 2;
    }

    .modalAcitve {
        padding-left: var(--cuarentaYochoPx);
    }

    .MuiToolbar-root nav .card-body .fiscaliaImagen2 {
        display: none;
    }

    .MuiBox-root nav .NavElement .listItem {
        padding: 0;
    }

    .iconButtonNav {
        display: none !important;
    }


    /* Assets List */

    .asset_icon {
        background-color: var(--assetIconBack) !important;
        padding: 10% !important;
    }

    .asset_icon .MuiIconButton-label img {
        width: 90%;
        height: 28px;
    }

    .contenedorBuscador {
        color: var(--placeholderText);
        margin: 0;
    }

    .makeStyles-title-10 {
        font-size: 1.875rem !important;
    }

    .iconsAssetTop {
        display: none;
    }

    .contenedorMedio .Fila1 {
        flex-direction: row;
    }

    .contenedorAssetGrid {
        padding: 0 3%;
    }


    /* Dialogs */

    .DialogOrdenar .MuiPaper-root {
        width: 56%;
    }

    #DialogOrdenar {
        padding: 0 2.95rem var(--diezciseisPx) 0.95rem !important;
    }

    #DialogOrdenar .MuiGrid-spacing-xs-2>.MuiGrid-item {
        padding: 0 !important;
    }

    #Ubicacion {
        background: var(--backField);
        border-radius: var(--treintaYdosPx);
        border: 0;
        color: var(--grisFont);
        width: 100%;
        padding-left: var(--diezciseisPx);
    }

    .dialogChangeLost .MuiDialog-container .MuiPaper-root {
        width: 50%;
    }

    #DialogOrdenar .MuiGrid-root .MuiGrid-root .MuiFormGroup-root.estado .MuiFormControlLabel-root {
        margin: 2% 0 !important;
        width: 50%;
    }

    .containerCorreoBienes1 .MuiDialog-paper {
        width: 40%;
    }

    .containerCorreoBienes1 .MuiDialog-container .MuiDialog-paper .MuiDialogActions-root {
        padding: 20px 7% !important;
    }

    .containerCorreoBienes1 .MuiDialog-container .MuiDialog-paper .MuiDialogActions-root .btnPrimary {
        padding: 7px 5% !important;
    }

    .containerCorreoBienes1 .MuiDialog-container .MuiDialog-paper .MuiDialogActions-root .btnSecundary {
        padding: 7px 4% !important;
    }

    .actionsSended {
        padding: 10px 7% !important;
    }


    /* Formulario report */

    .containerReport .MuiGrid-root .formReportSobrante {
        width: 65%;
    }

    .MuiOutlinedInput-input {
        padding: 11px 15% 11px 3% !important;
    }

    .cardRegistroSobrante .MuiGrid-root.MuiGrid-container {
        padding: 0 3% !important;
    }

    .btnEnviar1 {
        margin-top: 2% !important;
    }

    .ReportSobranteFooter .btnPrimary {
        padding: 6% 15% !important;
    }

    .ReportSobranteFooter .btnSecundary {
        padding: 6% 15% !important;
    }
}

@media (max-width: 991.98px) {


    /* Generales */

    .makeStyles-toolbar-4 {
        width: 94%;
    }

    .btnPrimary.btnGm {
        display: none !important;
    }

    .btnPrimary.btnA {
        display: flex;
        padding: 7px 5% !important;
    }

    .ContainerButtonGroup .fontAa {
        text-align: start;
        padding-left: 3%;
        width: 100%;
    }

    .displayNavLg {
        display: none !important;
    }

    .displayNavXs {
        display: flex !important;
    }

    .Documentos {
        display: none;
    }

    .cantidadDocumentos {
        justify-content: flex-start !important;
    }

    .orden2 {
        display: none;
    }

    .tittleCard2 {
        display: none;
    }

    #contenido {
        padding: 0.5% 2% 0;
        width: 100%;
        overflow: hidden;
    }

    .btnSecundary {
        color: var(--colorTittle) !important;
        border: 2px solid var(--whiteBack) !important;
        border-radius: 12px !important;
        text-transform: none !important;
        width: 10%;
        outline: none !important;
        font-family: 'Roboto', sans-serif;
        padding: 0.4rem 0.57rem !important;
    }

    .btnPrimary {
        padding: 7px 2% !important;
    }

    .padding0 {
        padding: 0% !important;
        padding-bottom: 0.6% !important;
        padding-right: 1% !important;
    }

    .btnEnviar1 {
        margin-top: 1% !important;
        padding: 6px 16px !important;
    }

    .makeStyles-title-592 {
        color: var(--blancoPuro) !important;
        font-size: var(--veintePx) !important;
    }

    .contenedorBuscador.main-container {
        color: var(--placeholderText);
        width: auto !important;
        margin: 0.5rem 0 !important;
        border-radius: 0px;
        padding: 0 !important;
    }


    /* Login */

    .containerLogin {
        width: 100vw;
        height: 100vh;
        text-align: center;
        justify-content: center;
        background-size: cover;
        background-image: url('./assets/img/imagen_congreso.jpg');
        background-position: center;
    }

    .containerLogin .rootLogin {
        width: 70%;
        float: none;
        margin: auto;
        background-color: var(--backBlue);
        color: var(--blancoPuro);
        border-radius: 0 0 12.25rem 12.25rem !important;
        padding: 3% 5% 5% 5%;
    }

    .MuiCardContent-root.cardTtuloLogin {
        width: 100%;
        margin: auto;
        max-width: none;
        padding: 10% 0 5% 0%;
    }

    .MuiTypography-root.tituloLogin {
        width: 100%;
        margin: auto;
        font-size: var(--treintaYseisPx) !important;
        font-weight: 800;
        line-height: 1;
        font-family: 'Roboto';
    }

    .btnLogin {
        background-color: var(--colorTittle) !important;
        width: 45% !important;
        font-size: var(--diezciseisPx) !important;
        color: var(--blancoPuro) !important;
        border-radius: var(--treintaYdosPx) !important;
        text-transform: none !important;
    }

    .field {
        background-color: var(--backField) !important;
        border-radius: 50px;
        color: var(--colorField) !important;
        padding-left: var(--ochoPx) !important;
        width: 45%;
        padding: var(--ochoPx) 0;
        border: 0;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: var(--catorcePx) !important;
    }

    .ImgLogoLogin {
        width: 60%;
        padding-top: 10%;
    }


    /* Barra lateral */

    .NavElement .modalAcitve {
        display: none;
    }

    .listItem {
        display: none;
        justify-content: center;
    }

    .fiscaliaImagen {
        display: none;
    }

    .fiscaliaImagen2 {
        display: unset;
        margin-bottom: 40%;
        margin-top: 20%;
    }

    .card-body {
        width: 100%;
        border-radius: 0;
        padding: 0;
    }

    .NavElement .MuiButtonBase-root {
        justify-content: center;
    }

    .NavElement.active .MuiListItemIcon-root {
        width: 70%;
        padding: 12%;
        right: auto;
    }

    .NavElement.active .MuiListItem-root {
        justify-content: center;
        margin: 20% 0;
    }

    .modalAcitve {
        display: none;
    }

    .modalActiveIcon {
        width: 80%;
        position: unset;
        padding: 12% !important;
    }

    .redirectIcon {
        padding: 7% 12%;
    }

    .redirectAcitve {
        display: none;
    }

    .MuiListItem-button.NavElement {
        justify-content: center;
    }

    .MuiListItem-root.NavElement .MuiListItemIcon-root {
        padding: 9% 15%;
    }

    .MuiBox-root nav {
        height: auto;
    }

    .MuiBox-root nav .NavElement.active .MuiListItem-root .MuiListItemText-root {
        display: unset;
        padding: 13% 0;
        padding-left: var(--cuarentaYochoPx);
        z-index: 1;
        margin-left: 5%;
    }

    .MuiBox-root nav .NavElement.active .MuiListItemIcon-root {
        background-color: var(--backBlueClaro);
        color: var(--colorBlueClaro) !important;
        text-align: center;
        position: absolute;
        right: 85%;
        padding: 4%;
        z-index: 2;
    }

    .MuiBox-root nav .NavElement .listItem {
        display: unset;
        min-width: 20%;
        min-height: fit-content;
    }

    .MuiBox-root nav .NavElement .listItem span {
        font-size: var(--catorcePx);
    }

    .MuiBox-root .fiscaliaImagenNav {
        display: unset !important;
        height: 10%;
    }

    .MuiBox-root .MuiToolbar-root nav .card-body {
        height: auto;
        padding-left: 7%;
    }

    .MuiBox-root nav .NavElement.active .MuiListItem-root .MuiListItemText-root .MuiTypography-root {
        font-size: var(--catorcePx);
    }

    .MuiBox-root nav .NavElement .MuiListItem-root .MuiListItemText-root .MuiTypography-root {
        font-size: var(--catorcePx);
    }


    /* Mui */

    .MuiButtonBase-root {
        justify-content: center;
    }

    .MuiSvgIcon-root {
        height: 2.5em !important;
    }

    .MuiBadge-badge.MuiBadge-anchorOriginBottomRightRectangular {
        right: 28%;
        bottom: auto;
        padding: 2px 18% !important;
        min-width: auto !important;
    }

    .MuiSvgIcon-root {
        height: auto !important;
    }

    .MuiCardActions-root.FooterForm .btnPrimary {
        padding: 7px 4% !important;
    }


    /* Assets */

    .makeStyles-img-33 {
        width: 1.875rem;
    }

    .textCards {
        font-size: var(--docePx) !important;
    }

    .MuiTypography-caption.descripcionBien {
        font-size: var(--trecePx) !important;
        padding: 0 10px;
    }

    .bAsset {
        font-size: var(--docePx);
    }

    .asset_icon {
        background-color: var(--assetIconBack) !important;
        padding: 14px 9% !important;
    }

    .asset_icon .MuiIconButton-label img {
        width: 100%;
        height: 28px;
    }

    .iconsAssetTop {
        display: none;
    }

    .contenedorMedio .Fila1 {
        flex-direction: row;
    }


    /* Dialogs */

    .dialogDocuments .MuiDialog-container .MuiPaper-root {
        padding-left: 2%;
        width: 60% !important;
    }

    .dialogDocuments .MuiDialogContent-root {
        padding: 0.5rem 1rem !important;
    }

    .DialogOrdenar .MuiPaper-root {
        width: 74%;
    }

    #DialogOrdenar {
        padding: 0 1.95rem var(--diezciseisPx) 0.95rem !important;
    }

    #DialogOrdenar .MuiGrid-spacing-xs-2>.MuiGrid-item {
        padding: 0 !important;
    }

    #Ubicacion {
        background: var(--backField);
        border-radius: var(--treintaYdosPx);
        border: 0;
        color: var(--grisFont);
        width: 100%;
        padding-left: var(--diezciseisPx);
    }

    .dialogChangeLost .MuiDialog-container .MuiPaper-root {
        width: 75%;
    }

    .dialogReounded .MuiDialog-container.MuiDialog-scrollPaper .MuiPaper-rounded {
        width: 60%;
    }

    #DialogOrdenar .MuiGrid-root .MuiGrid-root .MuiFormGroup-root.estado .MuiFormControlLabel-root {
        margin: 2% 0 !important;
        width: 50%;
    }

    #DialogOrdenar .MuiGrid-root .MuiGrid-root .MuiFormGroup-root.novedad .MuiFormControlLabel-root {
        margin: 0 !important;
        width: 49%;
    }

    .estadoBien.dialogReounded .MuiDialog-container.MuiDialog-scrollPaper .MuiPaper-rounded {
        width: 66%;
    }

    .containerCorreoBienes2 .MuiDialog-paper {
        width: 70%;
    }

    .containerCorreoBienes1 .MuiDialog-paper {
        width: 55%;
    }

    .containerCorreoBienes1 .MuiDialog-container .MuiDialog-paper .MuiDialogActions-root {
        padding: 16px 7% !important;
    }

    .containerCorreoBienes1 .MuiDialog-container .MuiDialog-paper .MuiDialogActions-root .btnPrimary {
        padding: 7px 5% !important;
    }

    .containerCorreoBienes1 .MuiDialog-container .MuiDialog-paper .MuiDialogActions-root .btnSecundary {
        padding: 7px 10% !important;
    }

    .actionsSended {
        padding: 10px 7% !important;
    }


    /* Formulario sobrante reportado */

    .containerReport .MuiGrid-root .formReportSobrante {
        width: 75%;
    }

    .MuiOutlinedInput-input {
        padding: 9px 15% 9px 3% !important;
    }

    .cardRegistroSobrante .MuiGrid-root.MuiGrid-container {
        padding: 0 2% !important;
    }

    .ReportSobranteFooter .btnPrimary {
        padding: 7% 10% !important;
    }

    .RoportInventory .MuiDialog-container .MuiPaper-root {
        width: 80% !important;
        height: auto !important;
    }

    .RoportInventory .MuiDialog-container .MuiPaper-root .MuiDialogActions-root {
        margin: 1% 5%;
    }

    .RoportInventory .MuiDialog-container .MuiPaper-root .MuiDialogActions-root .btnPrimary {
        padding: 7px 4% !important;
    }
}

@media (max-width: 767.98px) {


    /* Generales */

    .notificaciones1 {
        padding-right: 2rem;
    }

    .ButtonGroup{
        width: 100%;
    }

    .MuiToolbar-root h6.MuiTypography-root {
        overflow: visible;
    }  

    #contenido {
        padding: 0.5% 2% 0;
    }

    .contenedorAssetGridMayor .MuiGrid-root .MuiGrid-item {
        min-height: min-content;
    }

    .cantidadDocumentos {
        justify-content: flex-start !important;
    }

    .InventarioCreado {
        width: 29rem;
    }

    .orden2 {
        display: none;
    }

    .MuiBadge-badge.MuiBadge-anchorOriginBottomRightRectangular {
        right: 28%;
        bottom: 34%;
        padding: 2px 12% !important;
        width: fit-content !important;
        min-width: auto !important;
        height: fit-content !important;
    }

    .MuiFormControlLabel-root {
        margin: 0 !important;
    }

    .textArea {
        width: 90%;
    }

    .validando {
        width: 30 !important;
    }

    .makeStyles-textNav-110 {
        font-size: var(--docePx) !important;
    }

    .btnEnviar1 {
        display: none !important;
    }

    .btnEnviar2 {
        display: unset !important;
        margin-top: 1% !important;
        padding: 10px 3% !important;
        font-size: var(--trecePx) !important;
    }

    .actionsFinded {
        padding: 2% 7% !important;
    }


    /* Login */

    .containerLogin {
        width: 100vw;
        height: 100vh;
        text-align: center;
        justify-content: center;
        background-size: cover;
        background-position: center;
        background-image: url('./assets/img/imagen_congreso.jpg');
    }

    .containerLogin .rootLogin {
        width: 80%;
        float: none;
        background-color: var(--backBlue);
        color: var(--blancoPuro);
        border-end-start-radius: var(--cientoCuatroPx);
        border-end-end-radius: var(--cientoCuatroPx);
        padding: 10% 12% 8% 12%;
    }

    .MuiCardContent-root.cardTtuloLogin {
        width: 100%;
        margin: auto;
        padding-bottom: 5%;
    }

    .MuiTypography-root.tituloLogin {
        font-size: var(--treintaYseisPx) !important;
        font-weight: 800;
        line-height: 1;
        font-family: 'Roboto';
    }

    .btnLogin {
        background-color: var(--colorTittle) !important;
        width: 65% !important;
        font-size: var(--diezciseisPx) !important;
        color: var(--blancoPuro) !important;
        border-radius: var(--treintaYdosPx) !important;
        text-transform: none !important;
    }

    .field .MuiFormControl-root .MuiInputBase-input .menuItemColor.MuiListItem-root.MuiListItem-button:hover {
        background-color: var(--whiteBack) !important;
    }

    .field {
        background-color: var(--backField) !important;
        border-radius: 50px;
        color: var(--colorField) !important;
        padding-left: var(--diezciseisPx) !important;
        width: 65%;
        padding: 0.3rem 0;
        border: 0;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: var(--catorcePx) !important;
    }

    .field::placeholder {
        color: var(--colorField);
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: var(--catorcePx);
    }

    .field:focus {
        border: 0 !important;
        outline: none !important;
    }

    .ImgLogoLogin {
        width: 35%;
        height: auto;
        padding-top: 10%;
        padding-bottom: 0;
        margin-right: 0 !important;
    }


    /* Formulario reportar sobrante */

    .formReportSobrante .MuiCardContent-root {
        padding: 0 10% !important;
    }

    #Ubicacion {
        background: var(--backField);
        border-radius: var(--treintaYdosPx);
        border: 0;
        color: var(--grisFont);
        width: 100%;
        padding-left: var(--diezciseisPx);
    }

    .Fila1 {
        flex-direction: row;
    }

    .containerReport .MuiGrid-root .formReportSobrante {
        width: 80%;
    }

    .cardRegistroSobrante .MuiGrid-root.MuiGrid-container {
        padding: 0 3% !important;
    }

    .RoportInventory .MuiDialog-container .MuiPaper-root {
        width: 80% !important;
    }

    .RoportInventory .MuiDialog-container .MuiPaper-root .MuiDialogActions-root {
        margin: 1% 5%;
    }

    .RoportInventory .MuiDialog-container .MuiPaper-root .MuiDialogActions-root .btnPrimary {
        padding: 7px 5% !important;
    }


    /* Barra lateral */

    .NavElement.active .MuiListItemIcon-root {
        width: 75%;
        position: unset;
        padding: 12%;
    }

    .NavElement.active .MuiListItem-root {
        margin: 1% 0;
    }

    .redirectIcon {
        background-color: var(--backBlueClaro);
        color: var(--colorBlueClaro) !important;
        border-radius: 16px;
        z-index: 2;
        position: absolute;
        padding: 12%;
        width: fit-content;
        min-width: 1% !important;
    }


    /* Asset */

    .estadoFaltante .MuiButtonBase-root {
        padding: 0;
    }

    .contenedorMedio .Fila1 {
        flex-direction: row;
    }

    .iconsAsset {
        padding: 0;
        justify-content: end;
    }

    .iconsAssetTop {
        display: none;
    }

    .asset_icon {
        background-color: var(--assetIconBack) !important;
        width: 76px;
        height: 76px;
        padding: 8px !important;
    }

    .asset_icon .MuiIconButton-label img {
        width: 80%;
        height: 28px;
    }

    .tittleBienes {
        text-align: start;
        margin: 0 !important;
    }

    .MuiContainer-root .wrapper {
        align-items: center;
        padding-bottom: 2%;
    }

    .wrapper .wrapper {
        margin-right: 0 !important;
        width: 46%;
    }

    .WithStyles\(ForwardRef\(Switch\)\)-root-113 {
        margin: 0 !important;
    }


    /* Dialogs */

    .dialogChangeLost .MuiDialog-container .MuiPaper-root {
        width: 95%;
    }

    .dialogReounded .MuiDialog-container.MuiDialog-scrollPaper .MuiPaper-rounded {
        width: 75%;
    }

    .MuiOutlinedInput-input {
        padding: 10px 14% 10px 3% !important;
    }

    .containerCorreoBienes2 .MuiDialog-paper {
        width: 70%;
    }

    .containerCardCorreoBien .MuiDialog-paper {
        width: 90%;
    }

    .containerCorreoBienes1 .MuiDialog-paper {
        width: 70%;
    }

    .containerCorreoBienes1 .MuiDialog-container .MuiDialog-paper .MuiDialogActions-root {
        padding: 17px 7% !important;
    }

    .containerCorreoBienes1 .MuiDialog-container .MuiDialog-paper .MuiDialogActions-root .btnPrimary {
        padding: 7px 5% !important;
    }

    .containerCorreoBienes1 .MuiDialog-container .MuiDialog-paper .MuiDialogActions-root .btnSecundary {
        padding: 7px 10% !important;
    }

    .actionsSended {
        padding: 10px 7% !important;
    }
}

@media (max-width: 575.98px) {


    /* Generales */

    #ContenedorGneral{
        display: none;
    }

    #tittlePrincipal{
        display: none;
    }
    
    #tittleResponsive{
        display: flex;
    }

    .ContainerButtonGroup .MuiGrid-root {
        flex-direction: row;
    }

    .estadosContainer {
        justify-content: flex-start !important;
    }

    .displayNavLg {
        display: none !important;
    }

    .displayNavXs {
        display: flex !important;
    }

    .documentos,
    .documentos>.MuiGrid-root {
        justify-content: center !important;
    }

    .cantidadDocumentos {
        justify-content: center !important;
    }

    .tipoTramite {
        align-self: flex-start;
    }

    #contenido {
        padding: 0;
    }

    #main-list-data {
        padding: 2%;
    }

    .InfiniteScroll {
        padding: 0 1%;
        margin: 1% 0;
    }

    #contenido .MuiBox-root header.MuiAppBar-root {
        padding: 0;
    }

    .contenedorIconButton {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .dependenciaXs {
        display: flex !important;
    }

    .dependenciaLg {
        display: none !important;
    }

    .tittleCardConsulta .MuiCardHeader-content span {
        font-size: var(--diezYochoPx);
        padding: 0 !important;
    }

    .orden2 {
        display: flex !important;
        margin: auto 0% !important;
        margin-bottom: var(--ochoPx) !important;
    }

    .orden1 {
        display: none !important;
    }

    .novedad {
        justify-content: space-around !important;
    }

    .validando {
        font-size: var(--trecePx) !important;
    }

    .contenedorBuscador .main-container {
        margin: 0;
        padding-right: 0;
    }

    .MuiBadge-badge {
        font-size: var(--ochoPx) !important;
        height: 50% !important;
        padding: 0 !important;
        min-width: 60% !important;
    }

    .svg-inline--fa {
        height: var(--veintiCuatroPx);
    }

    .seccional {
        font-size: var(--diezciseisPx);
        padding: 5% 0 0;
    }

    .tittleBienes {
        font-size: var(--diezYochoPx);
        font-weight: 600;
        color: var(--grisFont);
        text-align: start;
        margin: 3% 0 !important;
    }

    .sincronizandoTittle {
        font-size: var(--diezciseisPx) !important;
    }

    .wrapper {
        flex-direction: column;
    }

    .wrapper .wrapper {
        flex-direction: initial;
        margin: 0 !important;
        width: 100%;
    }

    .wrapper .wrapper .MuiGrid-root {
        width: 100%;
    }

    .MuiFormControlLabel-root .MuiTypography-root {
        font-size: var(--trecePx);
    }

    .textArea {
        width: 90%;
    }

    .ubicacionInput {
        height: auto;
    }

    #Ubicacion {
        background: var(--backField);
        border-radius: var(--treintaYdosPx);
        border: 0;
        color: var(--grisFont);
        width: 100%;
        padding: 3%;
    }

    .ubicacionInput {
        width: 100%;
    }

    .MuiAutocomplete-root {
        width: auto !important;
    }

    .orden2 .MuiFormControlLabel-root {
        padding: 0;
    }

    .drawerNav .MuiPaper-root::-webkit-scrollbar {
        width: 6px;
    }

    .drawerNav .MuiPaper-root::-webkit-scrollbar-track {
        color: var(--colorTittle);
    }

    .drawerNav .MuiPaper-root::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px var(--colorTittle);
    }

    .drawerNav .MuiPaper-root.MuiDrawer-paper {
        width: 72% !important;
        background: var(--backBlue);
    }

    .drawerNav .MuiPaper-root.MuiDrawer-paper .MuiBox-root .MuiToolbar-root nav {
        width: 100%;
    }

    .InventarioCreado {
        width: auto;
    }

    .iconInfo {
        font-size: 7rem;
        margin: 5% 0;
        height: var(--treintaYdosPx);
    }

    .MuiGrid-root .MuiTypography-root {
        font-weight: 400;
        text-align: start;
    }

    .btnEnviar1 {
        margin-top: 1% !important;
        padding: 6px 16px !important;
        display: none !important;
    }

    .btnEnviar2 {
        display: flex !important;
        margin: 15% auto !important;
        padding: 10px 4% !important;
    }

    .iconInfo.svg-inline--fa.fa-li {
        width: var(--treintaYdosPx);
    }

    .MuiOutlinedInput-input {
        padding: 10px 15% 10px 3% !important;
    }

    .MuiAutocomplete-listbox .MuiAutocomplete-option {
        width: 100% !important;
        color: var(--grisFont) !important;
        font-size: 0.9rem !important;
    }

    .labelStyle {
        font-size: var(--trecePx) !important;
    }

    .actionsFinded {
        padding: 1% 7% !important;
    }


    /* Alertas */

    .alertaConsultaBienes {
        font-size: var(--trecePx) !important;
        font-weight: 500 !important;
        padding: 0 1% !important;
    }

    .alertaConsultaBienes .MuiAlert-icon {
        padding-left: 3% !important;
        align-items: flex-start;
    }

    .alertaRegistro .MuiAlert-message {
        line-height: 1;
    }


    /* Notificaciones */

    .mensajePrincipal {
        display: none;
    }

    .mensajeResponsive {
        display: flex;
    }

    .notificaciones1 {
        display: none;
    }

    .iconNotifycations {
        color: var(--blancoPuro);
    }

    .contenedorMensaje {
        width: 19%;
        padding: 1% 5%;
    }

    .tituloMensaje2 {
        display: flex !important;
        padding-bottom: 9px;
    }

    .tituloMensaje1 {
        display: none !important;
    }

    .contentMensaje {
        font-size: var(--trecePx) !important;
    }

    .notificacion {
        padding: 17px 8px !important;
    }

    .notificacion .MuiGrid-root {
        justify-content: center;
    }

    .contenedorNotificaciones {
        padding: 0 2%;
    }

    .listaNotificaiones {
        padding: 0 3%;
    }

    .listaNotificaiones div .InfiniteScroll {
        padding: 0;
    }

    .mensajeTituloP {
        padding-bottom: 10px;
    }

    .fechaNotificacion {
        text-align: start;
        padding-left: 1rem;
    }


    /* Login */

    .containerLogin {
        width: 100vw;
        height: 100vh;
        text-align: center;
        justify-content: center;
        background-size: cover;
        background-position: center;
        background-image: url('./assets/img/imagen_congreso.jpg');
    }

    .containerLogin .rootLogin {
        width: 90%;
        float: none;
        background-color: var(--backBlue);
        color: var(--blancoPuro);
        border-end-start-radius: var(--cientoCuatroPx);
        border-end-end-radius: var(--cientoCuatroPx);
        padding: 9% 12% 11% 12%;
    }

    .MuiCardContent-root.cardTtuloLogin {
        width: 100%;
        margin: auto;
        padding-bottom: 5%;
    }

    .MuiTypography-root.tituloLogin {
        font-size: var(--treintaYseisPx) !important;
        font-weight: 800;
        font-family: 'Roboto';
        line-height: 1;
    }

    .btnLogin {
        background-color: var(--colorTittle) !important;
        width: 60% !important;
        font-size: var(--diezciseisPx) !important;
        color: var(--blancoPuro) !important;
        border-radius: var(--treintaYdosPx) !important;
        text-transform: none !important;
    }

    .field {
        background-color: var(--backField) !important;
        border-radius: 50px;
        color: var(--colorField) !important;
        padding-left: var(--diezciseisPx) !important;
        width: 60%;
        padding: var(--ochoPx) 0;
        border: 0;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: var(--catorcePx) !important;
    }

    .ImgLogoLogin {
        width: 60%;
        height: auto;
        padding-top: 10%;
    }


    /* Navbar */

    .card-body .NavElement.active .MuiListItemIcon-root {
        width: fit-content;
        height: fit-content;
    }

    .NavElement.active .MuiListItem-root {
        margin: 0;
    }

    .MuiBox-root nav .NavElement.active .MuiListItemIcon-root {
        right: unset;
        left: -11px;
        padding: 10px;
        min-width: 1%;
    }

    .contenedorNone {
        display: none !important;
    }

    .iconButtonNav {
        display: unset !important;
    }

    .MuiAppBar-root {
        background-color: var(--backBlue) !important;
        color: var(--blancoPuro) !important;
    }

    .mainlist {
        display: none !important;
    }

    .MuiToolbar-root h6.MuiTypography-root {
        font-size: var(--veintePx) !important;
        color: var(--blancoPuro);
        font-weight: 800;
        padding-left: 4%;
        overflow: visible;
    }    

    .MuiToolbar-root h5.MuiTypography-root {
        font-size: var(--veintePx) !important;
        color: var(--blancoPuro);
        font-weight: 800;
    }

    .MuiBox-root nav .NavElement.active .MuiListItem-root .MuiListItemText-root {
        padding: 20px 0;
        padding-left: 17%;
        margin-left: 3%;
    }

    .redirectIcon {
        background-color: var(--backBlueClaro);
        color: var(--colorBlueClaro) !important;
        border-radius: 16px;
        z-index: 2;
        position: absolute;
        padding: 3%;
        width: fit-content;
        left: 0;
        min-width: 1% !important;
    }

    .redirectAcitve {
        padding: var(--diezciseisPx) 0;
        padding-left: 20%;
        z-index: 1;
        margin-left: 4%;
    }

    .modalAcitve {
        padding: 20px 0 20px 16%;
        margin-left: 5% !important;
    }

    .modalActiveIcon {
        position: absolute;
        padding: 4% !important;
        width: fit-content;
        left: 0;
        min-width: 1% !important;
    }

    .MuiBox-root .MuiToolbar-root nav .card-body {
        height: auto;
        padding-left: 5%;
    }

    .redirectIcon {
        padding: 4%;
    }

    .redirectAcitve {
        padding: 13% 0;
        padding-left: 20%;
    }

    .NavElement .modalAcitve {
        display: flex;
    }


    /* AssignedAssetList */

    .contenedorAbajo {
        flex-direction: column;
        align-content: center;
    }

    .contenedorAssetGrid {
        flex-direction: column;
        padding: 0;
    }

    .contenedorAssetGridMayor {
        flex-direction: column;
    }

    .Fila1 {
        flex-direction: column;
    }

    .asset_icon {
        width: 70px;
        height: 70px;
        padding: 3% !important;
    }

    .asset_icon .MuiIconButton-label img {
        width: 70%;
        height: 28px;
    }

    .contenedorMedio {
        width: 100% !important;
    }

    .contenedorBuscador {
        padding: 0;
        width: 100% !important;
        padding-right: 0;
    }

    .iconsAssetTop {
        display: unset;
        text-align: center;
    }

    .iconsAssetBottom {
        display: none !important;
    }

    .contenedorAssetGrid .columnaInfo {
        padding: 0 5%;
    }

    .contenedorAbajo {
        align-items: flex-start !important;
    }

    .containerMedio .MuiGrid-root .MuiGrid-root .MuiCardContent-root:last-child {
        text-align: left;
    }

    .contenedorAsset {
        padding: 0.5% 2% !important;
    }


    /* Dialogs */

    .DialogInventWithout .MuiDialog-container .MuiPaper-root {
        height: 30%;
    }

    .DialogInventWithout .MuiPaper-root .MuiDialogContent-root {
        width: 100%;
        padding: 0 0.2rem !important;
    }

    .MuiDialogContent-root {
        padding: 18px 15px 5px !important;
    }

    .estadoBien.dialogReounded .MuiDialog-container.MuiDialog-scrollPaper .MuiPaper-rounded {
        width: 100%;
        margin: 0;
    }

    .estadoBien .MuiDialog-container .MuiPaper-root .MuiTypography-root {
        font-size: var(--trecePx);
        text-align: justify;
    }

    .estadoBien .MuiDialog-container .MuiPaper-root .MuiDialogActions-root {
        margin-bottom: 5% !important;
    }

    .dialogChangeLost .MuiDialog-container .MuiPaper-root {
        width: 95%;
    }

    .dialogChangeLost .MuiDialog-container .MuiPaper-root .MuiDialogActions-root {
        margin-bottom: 5% !important;
    }

    .DialogOrdenar .MuiDialog-container .MuiPaper-root .MuiDialogActions-root {
        justify-content: center;
    }

    #DialogOrdenar .MuiGrid-root .MuiGrid-root .MuiFormGroup-root {
        justify-content: space-around;
    }

    .checklistDialogGeneral .MuiDialog-container .MuiPaper-root {
        margin: 0;
        width: 90%;
    }

    .CheckListDialog div .MuiTypography-root {
        font-size: var(--trecePx);
    }

    #DialogOrdenar {
        padding: 0 !important;
    }

    .DialogOrdenar .MuiPaper-root {
        width: 85%;
    }

    .dialogFiltros .MuiDialog-container .MuiPaper-root {
        width: 90% !important;
        height: auto !important;
        border-radius: 20px !important;
        box-shadow: none !important;
    }

    .dialogFiltros .MuiDialog-container .MuiPaper-root .MuiDialogActions-root {
        justify-content: center;
        padding: 0.5rem 1rem 1rem 1rem !important;
    }

    .PadDialogActions {
        padding: 30px 8% !important;
        padding-top: 0 !important;
    }

    .dialogUbication.dialogReounded .MuiDialog-container.MuiDialog-scrollPaper .MuiPaper-rounded {
        width: 95%;
    }

    #DialogOrdenar .MuiGrid-root .MuiGrid-root .MuiFormGroup-root .orden1 .MuiFormControlLabel-root {
        margin: auto 0% !important;
    }

    #DialogOrdenar .MuiGrid-root .MuiGrid-root .MuiFormGroup-root.estado .MuiFormControlLabel-root {
        margin: 2% 0 !important;
        width: 50%;
    }

    #DialogOrdenar .MuiGrid-root .MuiGrid-root .MuiFormGroup-root.novedad .MuiFormControlLabel-root {
        margin: 2% 0% !important;
    }

    .DialogOrdenar .MuiDialog-container .MuiPaper-root {
        height: 45% !important;
        padding: 0 !important;
    }

    .dialogDocuments .MuiDialog-container .MuiPaper-root {
        width: 90% !important;
        height: auto !important;
        padding: 0;
    }

    .dialogDocuments .MuiDialogContent-root {
        padding: 1rem 1.5rem !important;
    }

    .dialogDocuments .MuiDialog-container .MuiPaper-root .MuiDialogActions-root .MuiButtonBase-root {
        margin: 1% 2%;
    }

    .dialogReounded .MuiDialog-container.MuiDialog-scrollPaper .MuiPaper-rounded {
        width: 90%;
        height: auto;
        margin: 0;
    }

    .containerCorreoBienes1 .MuiDialog-paper {
        width: 90%;
        margin: 0;
    }

    .containerCorreoBienes2 .MuiDialog-paper {
        width: 95%;
        margin: 0;
    }

    .containerCardCorreoBien .MuiDialog-paper {
        width: 96%;
        margin: 0;
    }

    .checklistDialogGeneral .validando {
        width: 100% !important;
    }

    .dialogTttleReimpresión .MuiTypography-h6 {
        font-size: var(--diezYochoPx);
    }

    .containerCorreoBienes1 .MuiDialog-container .MuiDialog-paper .MuiDialogActions-root .btnSecundary {
        padding: 6px 5% !important;
    }

    .containerCorreoBienes1 .MuiDialog-container .MuiDialog-paper .MuiDialogActions-root .btnPrimary {
        padding: 6px 5% !important;
    }

    .RoportInventory .MuiDialog-container .MuiPaper-root {
        width: 80% !important;
    }

    .RoportInventory .MuiDialog-container .MuiPaper-root .MuiDialogActions-root {
        margin: 1% 5%;
        padding: 0.5rem 0rem 1rem !important;
    }

    #inventoryHistory .MuiDialog-container .MuiPaper-root {
        height: 37% !important;
    }


    /* Reportar sobrante */

    .formReportSobrante {
        width: 100%;
    }

    .formReportSobrante .MuiPaper-root .MuiCardContent-root {
        padding: 1% 6% !important;
    }

    .containerSobrante {
        padding: 0;
    }

    .cardRegistroSobrante .MuiGrid-root.MuiGrid-container {
        padding: 0 !important;
    }

    .cardRegistroSobrante .MuiGrid-root.MuiGrid-container.containerMedio {
        flex-direction: column;
    }

    .cardRegistroSobrante.MuiPaper-root.MuiCard-root.cardRegistroSobrante.MuiPaper-elevation1.MuiPaper-rounded {
        padding: 0 5%;
    }

    .oDivisor p {
        font-size: var(--catorcePx);
        color: var(--grisFont);
        text-align: center;
        margin: 0;
    }

    .reportForm .MuiGrid-root .formReportSobrante .MuiPaper-root .MuiCardHeader-root .MuiCardHeader-content {
        padding: 2% !important;
        padding-right: 0 !important;
    }

    .containerReport .MuiGrid-root .formReportSobrante {
        width: 100%;
        text-align: start;
    }

    .containerSobrante .MuiPaper-root .MuiAlert-message .MuiAlert-root {
        padding-left: 1%;
        padding: 0%;
    }

    .containerSobrante .MuiPaper-root.MuiAlert-root {
        padding: 6px 15px;
    }

    .tittleCard {
        padding: 5% 0 !important;
    }

    .tittleCard .MuiCardHeader-content span {
        font-size: var(--diezYochoPx) !important;
        font-weight: 800;
    }

    .tittleCard1 {
        display: none !important;
    }

    .tittleCard2 {
        display: flex !important;
    }

    .ReportSobranteFooter {
        justify-content: center;
    }

    .ReportSobranteFooter .btnPrimary {
        padding: 6% 15% !important;
    }

    .ReportSobranteFooter .btnSecundary {
        padding: 5% 15% !important;
    }

    .cardRegistroSobrante,
    .columnaInfo .MuiGrid-root .MuiGrid-item {
        margin: 1% 0 !important;
        line-height: 1;
        justify-content: space-between;
        min-height: 1rem;
    }

    .MuiGrid-root .MuiGrid-item .MuiButtonBase-root .MuiIconButton-label .MuiBadge-root .svg-inline--fa {
        height: var(--diezciseisPx) !important;
    }

    .btnEditar1 {
        display: none !important;
    }

    .btnEditar2 {
        display: inline-flex !important;
        padding: 0 !important;
    }

    .btnSecundary {
        width: auto;
        padding: 6px 4.1% !important;
        min-width: 5rem !important;
    }

    .btnPrimary {
        width: auto;
        padding: 7px 1rem !important;
        min-width: 5rem !important;
    }

    .MuiCardActions-root.FooterForm .btnPrimary {
        padding: 7px 6% !important;
    }

    .ReportSobranteFooter .btnSecundary {
        padding: 6% 15% !important;
    }

    .papperSurplus {
        margin: 5% 0 2% 0;
    }


    /* Actions */

    .actionsSended {
        padding: 10px 7% !important;
    }

    .actionsMailDialog {
        padding: 10px 10% !important;
    }

    .actionsAlertRegisterSurplus {
        padding: 5% 10% !important;
    }

    .assetDialogActions1 {
        padding: 5% 6% 3% 0% !important;
    }

    .assetDialogActions1 .btnPrimary {
        padding: 7px 7% !important;
    }

    .assetDialogActions2 {
        padding: var(--ochoPx) var(--veintiCuatroPx) !important;
    }


    /* Notifications */
    .MuiTypography-root.tittleNotification {
        padding-left: 1rem;
    }
}