.search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 15px;
}

.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 28px;
}

.item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.sectional {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.magazine {
    color: green;
}

.state {
    color: black;
}

.main-container {
    position: relative;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.25);
    margin-right: 1rem;
    margin-left: 1rem;
    padding: 0rem 1rem;
    justify-items: center;
    align-items: flex-end;
    display: flex;
    box-sizing: content-box;
    width: 100%;
}

.container-icon {
    height: 100%;
    pointer-events: none;
    margin-left: .5rem;
    margin-right: .5rem;
}

.container-results {
    position: absolute;
    z-index: 100000;
    margin-top: 2.5rem;
    left: 0;
    right: 0;
    top: 0;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.margin {
    margin: 0%;
    padding: 0%;
}

.InfiniteScroll {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.InfiniteScroll::-webkit-scrollbar {
    width: 10px;
}

.InfiniteScroll::-webkit-scrollbar-track {
    color: #879ee3;
}

.InfiniteScroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px #879ee3;
}


/* .InfiniteScroll::-webkit-scrollbar {
    display: none;
} */

.circular-progress {
    margin: auto !important;
}

.notify-read {
    background-color: #757de8;
}